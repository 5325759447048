export interface PathsItemProps {
  label: string;
  href?: string;
  hrefZh?: string;
  ga?: string;
  target?: React.HTMLAttributeAnchorTarget;
  fn?: () => void;
  // 是否加多语言前缀 true
  locale?: string | false;
}

type Paths = {
  label: string;
  children: PathsItemProps[];
}[];

const generatePaths = (t: (name: string) => string): Paths => {
  return [
    {
      label: t('导航-关于悟空'),
      children: [
        // { label: t('导航-企业历程'), href: '/aboutus/', ga: 'CompanyClick' },
        { label: t('导航-师资力量'), href: '/teachers/', ga: 'TeacherClick' },
        // { label: t('导航-教研团队'), href: '/', ga: '' },
        // { label: t('导航-新闻'), href: '/', ga: '' },
        {
          label: t('导航-博客'),
          href: 'https://www.wukongsch.com/blog/',
          hrefZh: 'https://www.wukongsch.com/blog/zh/',
          ga: 'BlogClick',
          locale: false,
          target: '_blank',
        },
        { label: t('导航-商务合作'), href: '/joinus/#wukong-cooperation', ga: 'BusinessClick' },
        { label: t('导航-加入我们'), href: '/joinus/#wukong-zhaopin', ga: 'JoinusClick' },
      ],
    },
    {
      label: t('导航-课程'),
      children: [
        { label: t('导航-悟空中文'), href: '/chinese/', ga: 'CourseChineseClick' },
        { label: t('导航-悟空国际数学'), href: '/math/', ga: 'CourseMathClick' },
        { label: t('导航-英文阅读写作'), href: '/english/', ga: 'CourseEnglishClick' },
      ],
    },
    // {
    //   label: t('导航-学习园地'),
    //   children: [
    //     { label: t('导航-中文资料'), href: '/', ga: '' },
    //     { label: t('导航-数学资料'), href: '/', ga: '' },
    //     { label: t('导航-英文资料'), href: '/', ga: '' },
    //     { label: t('导航-拓展资料'), href: '/', ga: '' },
    //     { label: t('导航-专题推荐'), href: '/', ga: '' },
    //   ],
    // },
    {
      label: t('导航-帮助中心'),
      children: [
        { label: t('导航-常见问题'), href: '/faq/', ga: 'FaqClick' },
        // { label: t('导航-联系我们'), href: '/aboutus/#contant', ga: 'ContactusClick' },
      ],
    },
  ];
};

export default generatePaths;
