export type theme = 'class' | 'default';

export interface PathsItemBase {
  href?: string;
  hrefZh?: string;
  target?: React.HTMLAttributeAnchorTarget;
  label?: string;
  ga?: string;
  // 是否加多语言前缀 true
  locale?: string | false;
  // 使用样式模版 目前课程页特殊样式
  theme?: theme;
  // pc隐藏
  hidePC?: boolean;
  isCurrentPath?: boolean;
  render?: () => React.ReactNode;
}
export interface Paths extends PathsItemBase {
  children?: SelectorChildrenItem[];
}
export interface SelectorChildrenItem extends PathsItemBase {
  navThemeAge?: string;
  navThemeInfo?: string;
  subject?: string;
}

const generatePaths = (t: (name: string) => string): Paths[] => {
  return [
    {
      label: t('导航-首页'),
      href: '/',
      ga: 'HomeClick',
      hidePC: true,
    },
    {
      label: t('导航-课程'),
      href: '',
      ga: 'CourseClick',
      theme: 'class',
      children: [
        {
          label: t('导航-悟空中文'),
          href: '/chinese/',
          ga: 'CourseChineseClick',
          navThemeAge: t('导航-悟空中文-年龄'),
          navThemeInfo: t('导航-悟空中文-介绍'),
          subject: 'chinese',
        },
        {
          label: t('导航-悟空国际数学'),
          href: '/math/',
          ga: 'CourseMathClick',
          navThemeAge: t('导航-悟空国际数学-年龄'),
          navThemeInfo: t('导航-悟空国际数学-介绍'),
          subject: 'math',
        },
        {
          label: t('导航-英文阅读写作'),
          href: '/english/',
          ga: 'CourseEnglishClick',
          navThemeAge: t('导航-英文阅读写作-年龄'),
          navThemeInfo: t('导航-英文阅读写作-介绍'),
          subject: 'english',
        },
      ],
    },
    {
      label: t('导航-选择悟空的理由'),
      href: '/why-wukong/',
      ga: 'WhyWuKongClick',
    },
    {
      label: t('导航-学习园地'),
      href: '/resources/chinese/',
      ga: '',
    },
    {
      label: t('导航-博客'),
      href: 'https://www.wukongsch.com/blog/',
      hrefZh: 'https://www.wukongsch.com/blog/zh/',
      ga: 'BlogClick',
      locale: false,
      target: '_blank',
      // children: [
      //   {
      //     label: t('导航-博客-分类1'),
      //     href: 'https://www.wukongsch.com/blog/c-learning-tips/',
      //     hrefZh: 'https://www.wukongsch.com/blog/zh/c-learning-tips-zh/',
      //     ga: 'BlogLearningTipsClick',
      //     locale: false,
      //     target: '_blank',
      //   },
      //   {
      //     label: t('导航-博客-分类2'),
      //     href: 'https://www.wukongsch.com/blog/c-wukong-sharings/',
      //     hrefZh: 'https://www.wukongsch.com/blog/zh/c-wukong-sharings-zh/',
      //     ga: 'BlogWukongSharingsClick',
      //     locale: false,
      //     target: '_blank',
      //   },
      //   {
      //     label: t('导航-博客-分类3'),
      //     href: 'https://www.wukongsch.com/blog/c-education-news/',
      //     hrefZh: 'https://www.wukongsch.com/blog/zh/c-education-info/',
      //     ga: 'BlogEducationNewsClick',
      //     locale: false,
      //     target: '_blank',
      //   },
      // ],
    },
    // {
    //   label: t('导航-关于我们'),
    //   href: '/aboutus/',
    //   ga: 'AboutUsClick',
    // },
    {
      label: t('导航-下载APP'),
      href: '/download/',
      ga: 'DownloadClick',
    },
    // {
    //   label: t('导航-悟空师资'),
    //   href: '/teachers/',
    //   ga: 'Faculty',
    // },
  ];
};

export default generatePaths;
