import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import Button from '@/components/Button';
import { MyContext } from '@/config/context-manager';
import { EDU_URL, getLoginReportL, SCH_URL } from '@/utils/constants';
import { GlobalLoginContext } from '@/context/GlobalLoginContest';
import Name from '@/components/Nav/components/AvatarActions/Name';
// eslint-disable-next-line import/no-named-as-default
import LogOut from '@/components/Nav/components/AvatarActions/LogOut';
import { Dialog, Transition } from '@headlessui/react';
import LeadButton from '@/components/LeadButton';
import useGTM from '@/hooks/useGtag';
import { getBasicInfo, traceEvent } from '@wk/wk-gatherer';
import { setTriggerEvent, setTriggerPvid } from '@/utils/token';
import { isFirstOfResources } from '@/utils/login';
import { objToQuery } from '@/utils/url';
import MenuIcon from './components/MenuIcon';
import generatePaths, { Paths } from './generatePaths';
import Select from './components/Select';

interface NavProps {
  className?: string;
}

const avatarActions = [
  {
    render: () => <Name />,
    key: 'name',
  },
  {
    render: () => <LogOut />,
    key: 'logout',
  },
];

// 移动端 一个弹出层 单独做  ui搞的东西 布局无法适配
const Nav = ({ className }: NavProps) => {
  const { handleLogin, student, handleGoToStuCenter, setLogoutDrawerVisible } = useContext(GlobalLoginContext);
  const router = useRouter();
  const { locale } = useContext(MyContext);
  const [openInMobile, setOpenInMobile] = useState(false);
  const openInMobileRef = useRef(false);
  const { t, i18n } = useTranslation(['common', 'path', 'login']);
  const sendDataToGTM = useGTM();
  const gaFix = 'O_Navigation_';

  // 获取当前路径
  const currentPath = router.pathname;

  // 遍历数据，判断当前页面的router.pathname是否存在于数据中，并添加isCurrentPath属性
  const markCurrentPath = (data: Paths[], cutPath: string): Paths[] => {
    return data.map((item: Paths) => {
      const newItem: Paths = { ...item };
      if (item.href === cutPath) {
        newItem.isCurrentPath = true;
        /* 如果学习园地页面，只需要包含/resources/，即可识别为当前激活项 */
      } else if (item.href?.includes('/resources/') && cutPath?.includes('/resources/')) {
        newItem.isCurrentPath = true;
      } else if (item.children) {
        const hasCurrentChild = item.children.some((child: any) => child.href === cutPath);
        if (hasCurrentChild) {
          newItem.isCurrentPath = true;
          newItem.children = markCurrentPath(item?.children, cutPath);
        }
      }
      return newItem;
    });
  };

  const navConfigs = useMemo(() => {
    const dataOld = generatePaths(t);
    const currentPathFix = `${currentPath}/`;
    const newdata = markCurrentPath(dataOld, currentPathFix);
    return newdata;
  }, [t, currentPath]);

  // 监听路由变化 避免在移动端点击了跳转地址 但导航未关闭
  useEffect(() => {
    const handleRouteChange = () => {
      if (openInMobileRef.current) {
        setOpenInMobile(false);
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleGA = (e: React.MouseEvent, gaName?: string, label?: string) => {
    if (gaName) {
      const gaNameFix = `${gaFix}${gaName}`;
      // e.stopPropagation(); // 这儿之前黄鑫加的，但记不起来当时为什么要加了。
      sendDataToGTM(gaNameFix);
      traceEvent({
        data: {
          _event: gaNameFix,
          // 点击的所有页面名称
          page_name: currentPath,
        },
      });
    }
  };

  const setCookie = (localeData: string) => {
    // 删除子域名语言，避免老用户cookie下存在2个NEXT_LOCALE

    Cookies.remove('NEXT_LOCALE');
    Cookies.set('NEXT_LOCALE', localeData, {
      domain:
        // eslint-disable-next-line no-nested-ternary
        process.env.NEXT_PUBLIC_STAGE === 'prod' ? SCH_URL : process.env.NEXT_PUBLIC_STAGE === 'test' ? EDU_URL : '',
      path: '/',
    });
  };

  const handleSwitchLanguages = () => {
    const lang = i18n.language === 'en' ? 'zh' : 'en';
    setCookie(lang);
    router.replace(router.asPath, router.asPath, { locale: lang }).then(() => {
      // 如果是学习园地页面，则需要刷新页面请求接口数据
      if (router.pathname.includes('/resources/')) {
        window.location.reload();
      }
    });
  };

  const handleMenuButtonClick = useCallback(() => {
    setOpenInMobile(!openInMobile);
    openInMobileRef.current = !openInMobile;
  }, [openInMobile]);
  const handleAvatarClick = () => {
    if (window.innerWidth <= 720) {
      setLogoutDrawerVisible(true);
    }
  };

  const navHtml = () => {
    return (
      <>
        {navConfigs.map((menuItem, index) => {
          let node: JSX.Element | '' = '';
          if (menuItem?.href) {
            node = (
              <strong>
                <Link
                  href={(menuItem?.hrefZh && router?.locale === 'zh' ? menuItem?.hrefZh : menuItem.href) || ''}
                  locale={menuItem?.locale}
                  target={menuItem?.target}
                  className="flex h-full items-center "
                >
                  {menuItem.label}
                </Link>
              </strong>
            );
          } else {
            node = (
              <span className=" ">
                <strong>{menuItem.label}</strong>
              </span>
            );
          }
          if (menuItem.children) {
            node = (
              <Select options={menuItem.children} theme={menuItem.theme}>
                {node}
              </Select>
            );
          }
          return (
            <li
              key={index}
              onClick={(e) => {
                handleGA(e, menuItem.ga, menuItem.label);
              }}
              className={cls(
                'group relative flex h-full cursor-pointer py-3 transition-colors lg:px-2.5 lg:py-0 lg:hover:text-wk-red-500  xl:px-5 [&_strong]:font-semibold [&_strong]:lg:font-normal',
                menuItem?.hidePC && 'lg:hidden',
                menuItem?.isCurrentPath && '[&_strong]:lg:font-semibold',
              )}
            >
              {node}
              {menuItem?.isCurrentPath && (
                <svg
                  className="absolute bottom-2.5 left-1/2 hidden -translate-x-1/2 lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="7"
                  viewBox="0 0 34 7"
                  fill="none"
                >
                  <path
                    d="M2 2C2 2 7.3125 5 17 5C26.6875 5 32 2 32 2"
                    stroke="url(#paint0_radial_3211_32293)"
                    strokeWidth="4"
                    strokeLinecap="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_3211_32293"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(17.625 6.28572) rotate(-90) scale(16.2857 25.0488)"
                    >
                      <stop offset="0.0152376" stopColor="#FF5353" />
                      <stop offset="1" stopColor="#FF5353" stopOpacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              )}
            </li>
          );
        })}
      </>
    );
  };
  return (
    <>
      <header className={cls('fixed left-0 top-3 z-50 flex w-full  justify-center lg:top-6', className)}>
        <div className="container ">
          <div className="flex h-[57px] w-full items-center justify-between rounded-full border border-wk-bg-1 bg-white/85 pl-6 pr-3 text-sm text-wk-words-1 shadow-[0px_12px_20px_0px_rgba(0,0,0,0.06)] backdrop-blur-[20px] lg:h-[68px] lg:justify-start lg:pl-6  lg:pr-4 xl:h-[70] xl:pl-10 xl:text-base">
            <div className=" inline-block lg:hidden" onClick={handleMenuButtonClick}>
              <MenuIcon open={openInMobile} />
            </div>
            <Link
              href="/"
              className=" absolute left-1/2 h-6  w-[69px] translate-x-[-50%] lg:relative lg:left-auto lg:flex lg:translate-x-0 lg:justify-center xl:h-[32px] xl:w-[92px]"
              onClick={(e) => {
                handleGA(e, 'Logo');
              }}
            >
              <strong className="visuallyHidden">wukong</strong>
              <Image src="/images/icon/logo-red.svg" className="" width={92} height={32} alt="wukong" />
            </Link>
            <nav className=" hidden  h-full flex-1 lg:block">
              <ul className="flex h-full items-center lg:ml-5  xl:ml-10 ">{navHtml()}</ul>
            </nav>

            <div className="relative flex items-center justify-center lg:space-x-3 xl:space-x-6">
              <div
                className="  hidden cursor-pointer items-center justify-center rounded-full bg-[linear-gradient(94deg,rgba(156,65,199,0.06)_0.44%,rgba(63,100,253,0.06)_44.33%,rgba(2,156,255,0.06)_97%)] px-2.5  py-1.5 lg:flex"
                onClick={(e) => {
                  handleGA(e, 'LanguageClick');
                  handleSwitchLanguages();
                }}
              >
                <Image width={16} height={16} alt="WuKong" src="/images/nav/language.svg" />
                <span className=" ml-1 text-sm">{locale === 'en' ? t('导航-多语言-英文') : t('导航-多语言-中文')}</span>
              </div>
              {student ? (
                <Button
                  className="hidden h-9 px-4 lg:block lg:h-[38px]  lg:px-5 "
                  onClick={() => handleGoToStuCenter()}
                  traceName={`${gaFix}GoStudentClick`}
                  traceGa4Name="new_entry_Nav_GoStudent"
                  traceData={{ page_name: currentPath }}
                >
                  {t('导航-进入学员中心')}
                </Button>
              ) : (
                <Button
                  theme="empty"
                  className={cls('h-9 border border-wk-words-1 px-4  text-wk-words-1  lg:block lg:h-[38px]  lg:px-5')}
                  onClick={() => {
                    setTriggerPvid(getBasicInfo().pvid as string);
                    setTriggerEvent('O_Login_ButtonClick');
                    traceEvent({
                      data: {
                        _event: 'O_Login_ButtonClick',
                        payload: {
                          l: getLoginReportL(),
                          url: window.location.href,
                          page_name: router.pathname,
                          button_name: '登录',
                          re_event: '',
                        },
                      },
                    });
                    handleLogin({ source: isFirstOfResources() ? 'LEARNING_GARDEN_LOGIN' : 'STU_CENTER' });
                  }}
                  traceName={`${gaFix}LogInClick`}
                  traceGa4Name="new_entry_Nav_HeadLogin"
                  traceData={{ page_name: currentPath }}
                >
                  {t('导航-登录')}
                </Button>
              )}
              {student ? (
                <div className="relative flex">
                  <Select options={avatarActions} align="right" hiddenArrow bodyClassName="hidden lg:block">
                    <div
                      onClick={handleAvatarClick}
                      className="h-[38px] w-[38px] cursor-pointer overflow-hidden rounded-full border border-solid border-wk-border-3 bg-[#ffb9b9]"
                    >
                      <Image
                        className="object-cover"
                        alt="header"
                        src={student?.data?.HeadImageUrl || '/images/login/avatar.svg'}
                        width={38}
                        height={38}
                      />
                    </div>
                  </Select>
                </div>
              ) : (
                <LeadButton
                  theme="colorful"
                  className="hidden h-9 px-4 lg:block lg:h-[38px] lg:px-5"
                  onClick={() => {
                    setTriggerPvid(getBasicInfo().pvid as string);
                    setTriggerEvent(`${gaFix}CTAClick`);
                    const urlSearchParams = new URLSearchParams(window.location.search);
                    const l = urlSearchParams.get('l') || '';
                    const queryString = objToQuery({
                      booking_triggerevent: `${gaFix}CTAClick`,
                      l,
                    });
                    router.push(`/independent-appointment/${queryString}`);
                  }}
                  traceName={`${gaFix}CTAClick`}
                  traceGa4Name="new_entry_Nav_FreeReceiveCTA"
                  traceData={{ page_name: currentPath }}
                >
                  {t('导航-免费试课')}
                </LeadButton>
              )}
            </div>
          </div>
        </div>
      </header>
      <Transition appear show={openInMobile} as={Fragment}>
        <Dialog as="div" className="relative z-[999999]" onClose={handleMenuButtonClick}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed  bottom-0 left-0 right-0 top-0 " />
          </Transition.Child>

          <div className="fixed  bottom-0 left-0 right-0 top-0 overflow-y-auto">
            <div className="relative flex min-h-full items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cls(
                    'relative h-screen w-full transform overflow-hidden  bg-white/50 backdrop-blur-2xl transition-all',
                  )}
                >
                  {/* 头部 */}
                  <div className=" flex h-[84px] items-center justify-between bg-white  px-11 shadow-wk1">
                    <div className=" inline-block lg:hidden" onClick={handleMenuButtonClick}>
                      <MenuIcon open={openInMobile} />
                    </div>
                    <div
                      className="flex cursor-pointer items-center justify-center rounded-full bg-[linear-gradient(94deg,rgba(156,65,199,0.06)_0.44%,rgba(63,100,253,0.06)_44.33%,rgba(2,156,255,0.06)_97%)] px-2.5 py-1.5"
                      onClick={(e) => {
                        handleGA(e, 'LanguageClick');
                        handleSwitchLanguages();
                      }}
                    >
                      <Image width={16} height={16} alt="WuKong" src="/images/nav/language.svg" />
                      <span className=" ml-1 text-sm">
                        {locale === 'en' ? t('导航-多语言-英文') : t('导航-多语言-中文')}
                      </span>
                    </div>
                  </div>
                  {/* 内容 */}
                  <div className="h-[calc(100vh-84px)] overflow-y-scroll pb-6">
                    <nav className="">
                      <ul className="flex flex-col space-y-6 px-11  py-6 text-xl">{navHtml()}</ul>
                    </nav>
                    {/* 按钮区域 */}
                    {!student && (
                      <div className="px-11 ">
                        <LeadButton
                          theme="colorful"
                          className="w-full py-4 text-xl font-semibold"
                          onClick={() => {
                            setTriggerPvid(getBasicInfo().pvid as string);
                            setTriggerEvent(`${gaFix}CTAClick`);
                            const urlSearchParams = new URLSearchParams(window.location.search);
                            const l = urlSearchParams.get('l') || '';
                            const queryString = objToQuery({
                              booking_triggerevent: `${gaFix}CTAClick`,
                              l,
                            });
                            router.push(`/independent-appointment/${queryString}`);
                          }}
                          traceName={`${gaFix}CTAClick`}
                          traceGa4Name="new_entry_Nav_FreeReceiveCTA"
                          traceData={{ page_name: currentPath }}
                        >
                          {t('导航-免费试课')}
                        </LeadButton>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default Nav;
