import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import { getCountry } from '@/api/common';
import Title from '@/components/Title';
import LeadButton from '@/components/LeadButton';
import cls from 'classnames';
import useGTM from '@/hooks/useGtag';
import { MyContext } from '@/config/context-manager';
import { traceEvent } from '@wk/wk-gatherer';
import { useRouter } from 'next/router';
import { getGa4PageName } from '@/utils/utils';
import useContactInfo from '@/store/useContactInfo';
import footerPaths, { PathsItemProps } from './generatePaths';

const Form = dynamic(() => import('./FooterForm'), {
  ssr: false,
});

interface ITitle {
  tele?: string;
  teleZh: string;
  nation?: string;
  enName?: string;
  city?: string;
}

const teleInfoList: ITitle[] = [
  { enName: 'United States', city: '洛杉矶', nation: '美国', teleZh: '+1 (628) 333-2966', tele: '+1 (628) 358-9588' },
  { enName: 'Australia', city: '悉尼', nation: '澳大利亚', teleZh: '+61 7 31854732', tele: '+61 2 99116620' },
  { enName: 'New Zealand', city: '奥克兰', nation: '新西兰', teleZh: '+64 98709184', tele: '+64 98717076' },
  { enName: 'Canada', city: '多伦多', nation: ' 加拿大', teleZh: '+1 8672928226', tele: '+1 6479561731' },
  { enName: 'Singapore', city: '新加坡', nation: '新加坡', teleZh: '+65 69945213', tele: '+65 69945216' },
  { enName: 'United Kingdom', city: '伦敦', nation: '英国', teleZh: '+44 1135246470', tele: '+44 2033109899' },
  { enName: 'France', city: '巴黎', nation: '法国', teleZh: '+33 355409177', tele: '+33 259510005' },
  { enName: 'Spain', city: '马德里', nation: '西班牙', teleZh: '+34 978980565', tele: '+34 858887362' },
];

const socialMediaList = [
  {
    title: 'facebook',
    ga: 'facebook',
    src: '/images/footer/media-1.png',
    link: 'https://www.facebook.com/wukongedu/',
  },
  {
    title: 'Youtube',
    ga: 'youtube',
    src: '/images/footer/media-2.png',
    link: 'https://www.youtube.com/channel/UC_Ey8x88zFKeJ8pesQ6y_Aw',
  },
  {
    title: 'instagram',
    ga: 'Instagram',
    src: '/images/footer/media-3.png',
    link: 'https://www.instagram.com/wukongchinese/ ',
  },
  {
    title: 'linkedin',
    ga: 'linkedin',
    src: '/images/footer/media-4.png',
    link: 'https://cn.linkedin.com/company/wukong-education',
  },
  {
    title: 'redbook',
    ga: 'redbook',
    src: '/images/footer/media-5.png',
    link: 'https://www.xiaohongshu.com/user/profile/5c23fc910000000005015d94?xhsshare=CopyLink&appuid=5c23fc910000000005015d94&apptime=1607474453',
  },
  {
    title: 'weibo',
    ga: 'weibo',
    src: '/images/footer/media-6.png',
    link: 'https://weibo.com/kiwipedia123?refer_flag=1005055013_',
  },
  {
    title: 'wechat',
    ga: 'wechat',
    src: '/images/footer/media-7.png',
    link: 'https://mp.weixin.qq.com/s/JIPsZoM8z8N8wGESy1gwyg',
  },
];

const honourList = [
  {
    text1: '导航-荣誉-1-1',
    text2: '导航-荣誉-1-2',
  },
  {
    text1: '导航-荣誉-2-1',
    text2: '导航-荣誉-2-2',
  },
  {
    text1: '导航-荣誉-3-1',
    text2: '导航-荣誉-3-2',
  },
];

const paymentMethods = [
  { src: '/images/footer/pay-9.png' },
  { src: '/images/footer/pay-1.png' },
  { src: '/images/footer/pay-2.png' },
  { src: '/images/footer/pay-10.png' },
  { src: '/images/footer/pay-discover.png' },
  { src: '/images/footer/pay-3.png' },
  // { src: '/images/footer/pay-4.png' },
  { src: '/images/footer/pay-5.png' },
  { src: '/images/footer/pay-6.png' },
  { src: '/images/footer/pay-7.png' },
  // { src: '/images/footer/pay-8.png' },
];

export interface FooterProps {
  tooterTheme?: 'home' | 'base';
}

// 删除 迁移到首页
const LandingHome = () => {
  const { t } = useTranslation(['common', 'path']);
  const router = useRouter();
  // 获取当前路径
  const currentPath = router.pathname;

  return (
    <section className="relative h-[253px] overflow-hidden bg-gradient-to-b from-[#1B1F4D] to-[#561499] lg:-mb-56 lg:h-[583px] xl:-mb-72 xl:h-[800px]">
      <div className="absolute  bottom-0 left-1/2 right-0 top-0 z-10  w-[200%] translate-x-[-50%] lg:left-0 lg:w-full lg:translate-x-0">
        <Image
          src="/images/footer/footer-landing-full-bg.png"
          alt="WuKong"
          sizes="100vw"
          width={1686}
          height={1686}
          className="mx-auto mt-15 lg:mt-20 xl:mt-[100px] "
        />
      </div>
      <div className=" bg-[linear-gradient(180deg,_#1d1f50_13%,rgba(86,_20,_152,_0.5)_100%)] " />
      <div className="from-12.51%  absolute bottom-0 left-0 right-0   top-0 z-20 w-full  bg-gradient-to-b from-[#1D1F50_12.51%] to-[rgba(86,_20,_152,_0.5)_100%] pt-12 lg:to-transparent lg:pt-[120px] xl:pt-40 ">
        <div className="container text-center">
          <Title className="[&]:text-white">{t('尾部留资-标题')}</Title>
          <LeadButton
            traceName="O_CTAModule_CTAClick"
            traceGa4Name={`new_entry_${getGa4PageName(currentPath)}_CTAModuleCTA`}
            target="_blank"
            theme="colorfulBorder"
            size="xl"
            className="mt-6 font-semibold lg:mt-12 xl:mt-15 "
            traceData={{
              page_name: currentPath,
            }}
            htmlLabel="strong"
          >
            {t('尾部留资-按钮')}
          </LeadButton>
        </div>
      </div>
    </section>
  );
};

// 删除 迁移到首页
const LandingBase = () => {
  return <></>;
};

const mail = 'contact@wukongsch.com';
const gaFix = 'O_Footer_';
const Footer = ({ tooterTheme = 'base' }: FooterProps) => {
  const { framework } = useContext(MyContext);
  const setContactInfo = useContactInfo((state) => state.setContactInfo);
  const { t, i18n } = useTranslation(['common']);
  const [showICP, setShowICP] = useState(false);
  const [curLoginNationTeleList, setCurLoginNationTeleList] = useState<ITitle[]>();
  const sendDataToGTM = useGTM();
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShowICP(window?.location?.origin?.includes('wukongedu.net'));
    }
  }, [router]);

  // 获取当前路径
  const currentPath = router.pathname;

  const landingTheme = {
    home: LandingHome,
    base: LandingBase,
  };

  // 删除 迁移到首页
  const LandingHtml = landingTheme[tooterTheme];

  const openKaamel = () => {
    if (window?.kaamelObj) {
      window?.kaamelObj?.openPanel();
    }
  };

  const navConfigs = useMemo(() => {
    const oldArr = footerPaths(t);
    // 展示cookie设置
    // if (framework && framework.framework && framework.finished) {
    //   oldArr[2]?.children?.push({ label: t('隐私设置'), ga: 'privacySettings', fn: openKaamel });
    // }
    return oldArr;
  }, [t, framework]);

  const showFramework = useMemo(() => {
    // 展示cookie设置
    if (framework && framework.framework && framework.finished) {
      return true;
    }
    return false;
  }, [framework]);

  const handleGA = (gaName?: string, extra: { [key: string]: any } = {}) => {
    if (gaName) {
      const gaNameFix = `${gaFix}${gaName}`;
      sendDataToGTM(gaName);
      traceEvent({
        data: {
          _event: gaNameFix,
          // 点击的所有页面名称
          page_name: currentPath,
          ...extra,
        },
      });
    }
  };

  const navNode = (child: PathsItemProps[]) => {
    return (
      <>
        {child?.map((item, i) => {
          return (
            <p
              className="mt-3 cursor-pointer text-sm  opacity-85 transition-opacity hover:opacity-100 lg:text-xs xl:mt-6 xl:text-sm"
              key={i}
              onClick={() => {
                handleGA(item.ga);
                if (item?.fn) {
                  item?.fn();
                }
              }}
            >
              {!item.href ? (
                <span>{item.label}</span>
              ) : (
                <Link
                  href={(item?.hrefZh && i18n?.language === 'zh' ? item?.hrefZh : item.href) || ''}
                  locale={item?.locale}
                  target={item?.target}
                >
                  {item.label}
                </Link>
              )}
            </p>
          );
        })}
      </>
    );
  };

  const getCurLoationTele = () => {
    const CUR_LOGIN_NATION_TETE = window.sessionStorage.getItem('CUR_LOGIN_NATION_TETE');
    if (CUR_LOGIN_NATION_TETE && CUR_LOGIN_NATION_TETE.indexOf('teleZh') !== -1) {
      setCurLoginNationTeleList(CUR_LOGIN_NATION_TETE ? JSON.parse(CUR_LOGIN_NATION_TETE) : [{}]);
    } else {
      const curDate = new Date();
      const curTime = `${curDate.getMonth()}/${curDate.getDate()}/${curDate.getFullYear()} ${curDate.getHours()}:${curDate.getMinutes()}:${curDate.getSeconds()}`;
      getCountry({
        time: curTime,
      })
        .then((response) => {
          const curLoginNation = response.data.country;
          const newCurLoginNationTeleList = teleInfoList.filter((item) => {
            return item.enName === curLoginNation;
          });
          if (newCurLoginNationTeleList?.length > 0) {
            setCurLoginNationTeleList(newCurLoginNationTeleList);
            setContactInfo(newCurLoginNationTeleList[0]);
            window.sessionStorage.setItem('CUR_LOGIN_NATION_TETE', JSON.stringify(newCurLoginNationTeleList));
          } else {
            const defaultData: ITitle[] = [
              {
                enName: 'New Zealand',
                city: '奥克兰',
                nation: '新西兰',
                teleZh: '+64 98709184',
                tele: '+64 98717076',
              },
            ];
            setCurLoginNationTeleList(defaultData);
            window.sessionStorage.setItem('CUR_LOGIN_NATION_TETE', JSON.stringify(defaultData));
          }
        })
        .catch(() => {
          const defaultData: ITitle[] = [
            {
              enName: 'New Zealand',
              city: '奥克兰',
              nation: '新西兰',
              teleZh: '+64 98709184',
              tele: '+64 98717076',
            },
          ];
          setCurLoginNationTeleList(defaultData);
        });
    }
  };

  useEffect(() => {
    getCurLoationTele();
  }, []);

  const title = 'xl:text-base lg:text-sm text-base font-semibold';

  const honourHtml = () => {
    return (
      <div className="mt-10 flex flex-col items-center justify-center space-y-10 md:flex-row md:space-x-15 md:space-y-0 lg:mt-0 xl:justify-between xl:space-x-0">
        {honourList.map((item, index) => (
          <div className="flex w-44 justify-between text-center text-xs" key={index}>
            <Image src="/images/footer/olive.svg" width={26} height={56} alt="WuKong" className="" />
            <div>
              <p className="font-semibold" dangerouslySetInnerHTML={{ __html: t(item.text1) }} />
              <p className=" mt-1 opacity-45" dangerouslySetInnerHTML={{ __html: t(item.text2) }} />
            </div>
            <Image src="/images/footer/olive.svg" width={26} height={56} alt="WuKong" className="rotate-y-180" />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div data-expose-event="O_FooterExpose">
      {/* 删除 迁移到首页 */}
      <LandingHtml />
      <footer className="relative mx-auto text-white lg:max-w-screen-lg lg:px-5 xl:max-w-screen-xl ">
        <div className=" absolute  z-30 hidden lg:-top-36 lg:right-8 lg:block xl:-top-[200px] xl:right-[110px]">
          <Image
            src="/images/footer/footer-landing-ip.png"
            className=" lg:h-[172px] lg:w-[260px]  xl:h-[236px] xl:w-[357px] "
            alt="WuKong"
            width={357}
            height={236}
          />
        </div>
        <div className=" relative z-20 bg-[#31355F] p-5 lg:rounded-5xl lg:p-10 xl:p-15">
          <div className=" lg:flex lg:min-h-[380px] xl:min-h-[529px]">
            <div className="w-full lg:flex lg:w-[52.3%] lg:flex-col lg:justify-between xl:w-[53.5%]">
              <nav className="grid grid-cols-2 gap-x-10 gap-y-10  md:grid-cols-4 md:gap-x-6 md:gap-y-0 xl:gap-x-10 ">
                {navConfigs.map((item, i) => {
                  const childNode = navNode(item.children);
                  return (
                    <div key={i} className="odd:border-r odd:border-white/8 md:border-none">
                      <p className={cls('mb-6 lg:text-sm xl:mb-10 ', title)}>{item.label}</p>
                      <div>{childNode}</div>
                    </div>
                  );
                })}
              </nav>
              <div className=" hidden lg:block">
                <p className={cls('mt-10  xl:mt-15 ', title)}>{t('导航-付款方式')}</p>
                <ul className="flex flex-wrap ">
                  {paymentMethods.map((item, i) => (
                    <li className="mr-2 mt-2 lg:mr-4 lg:mt-4" key={i}>
                      <Image
                        src={item.src}
                        alt="WuKong payment"
                        className="h-12 w-[106px] lg:h-10 lg:w-[88px] xl:h-12 xl:w-[106px]"
                        width={106}
                        height={48}
                        quality={90}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex w-full flex-col  justify-between lg:w-[47.7%]  lg:border-l   lg:border-white/8 lg:pl-10 xl:w-[46.5%]">
              <div className="mt-10 lg:mt-0">
                <p className={cls(title)}>{t('导航-订阅-标题')}</p>
                <Form />
              </div>
              <div className="mt-10 lg:mt-0">
                <p className={cls(title)}>{t('导航-下载我们的应用')}</p>
                <div className="mt-4 flex justify-between">
                  <div className="w-[42.85%]">
                    <Link
                      href="/download/"
                      onClick={() => {
                        handleGA('IOSDownloadClick');
                      }}
                    >
                      <Image
                        src="/images/footer/footer-apple.png"
                        width={240}
                        height={80}
                        alt="WuKong class download on the app store"
                      />
                    </Link>
                  </div>
                  <div className="w-[47.5%]">
                    <Link
                      href="/download/"
                      onClick={() => {
                        handleGA('AndroidDownloadClick');
                      }}
                    >
                      <Image
                        width={266}
                        height={80}
                        src="/images/footer/footer-google.png"
                        alt="WuKong class download on the google paly"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="mt-10 text-center lg:mt-0">
                <a href="https://www.kidsafeseal.com/certifiedproducts/wukong.html" target="_blank" rel="noreferrer">
                  <span className="visuallyHidden">kidsafe</span>
                  <img
                    className="m-auto h-[50px] w-36 xl:h-[60px] xl:w-[173px]"
                    src="https://www.kidsafeseal.com/sealimage/886916793933804774/wukong_extralarge_darktm.png"
                    alt="Wukong EDU (web and mobile) is a member of the kidSAFE Seal Program."
                  />
                </a>
                {/* <p className={cls('mt-4 text-xs lg:text-sm xl:text-base')}>{t('导航-kidsafe')}</p> */}
              </div>
              <ul className="mt-8 flex flex-wrap justify-center lg:mt-0 lg:flex-nowrap lg:space-x-6 ">
                {socialMediaList.map((item) => (
                  <li
                    key={item.title}
                    className=" m-2 h-11 w-11 opacity-50  transition-opacity hover:opacity-100 lg:m-0  lg:h-9 lg:w-9 xl:h-11 xl:w-11"
                    onClick={() => {
                      handleGA('SocialMediaClick', { SocialMedia_name: item.ga });
                    }}
                  >
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <Image
                        src={item.src}
                        className="h-11 w-11 rounded-md bg-white lg:h-9 lg:w-9  xl:h-11 xl:w-11"
                        width={44}
                        height={44}
                        alt={`WuKong ${item.title}`}
                      />
                    </a>
                  </li>
                ))}
              </ul>
              <div className="hidden xl:block">{honourHtml()}</div>
            </div>
          </div>
          <div className="mt-8 hidden lg:block xl:hidden">{honourHtml()}</div>
          <div className=" mt-10 rounded-3xl bg-white bg-opacity-8 px-6 py-6 text-white lg:px-3 lg:py-3 xl:mt-15">
            <ul className="flex flex-col space-y-6 text-sm font-medium lg:flex-row lg:space-y-0 lg:text-xs xl:text-sm">
              <li className="flex flex-1 items-center justify-start lg:justify-center">
                <Image
                  src="/images/footer/icon-phone.svg"
                  width={16}
                  height={16}
                  alt="phone"
                  className="mr-3 h-4 w-4  "
                />
                {curLoginNationTeleList && curLoginNationTeleList[0] && (
                  <a href={`tel:${curLoginNationTeleList[0].tele}`} className={curLoginNationTeleList[0].tele}>
                    {curLoginNationTeleList[0].tele}
                  </a>
                )}
              </li>
              <li className="flex flex-1 items-center justify-start lg:justify-center">
                <Image src="/images/footer/icon-mail.svg" width={16} height={16} alt="mail" className="mr-3 h-4 w-4 " />
                <a href={`mailto:${mail}`}>{mail}</a>
              </li>
              <li className="flex flex-1 items-center justify-start lg:justify-center">
                <Image
                  src="/images/footer/icon-address.svg"
                  width={16}
                  height={16}
                  alt="address"
                  className="mr-3 h-4 w-4 "
                />
                <div>
                  <p>{t('地址-美国-标题')}</p>
                  <p className="mt-1  font-normal opacity-45">{t('地址-美国-地址')}</p>
                </div>
              </li>
              <li className="flex flex-1 items-center justify-start lg:justify-center">
                <Image
                  src="/images/footer/icon-address.svg"
                  width={16}
                  height={16}
                  alt="address"
                  className="mr-3 h-4 w-4 "
                />
                <div>
                  <p>{t('地址-新西兰-标题')}</p>
                  <p className="mt-1  font-normal opacity-45">{t('地址-新西兰-地址')}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="sticky  bottom-0 left-0 right-0 z-10"> */}
        <div className="">
          <div className="mt-6 flex flex-wrap  justify-center px-5  text-xs text-wk-words-1 opacity-85 xl:mt-10 xl:text-sm">
            <p className="mx-2 my-0.5 text-wk-words-2">{t('导航-悟空教育年份')}</p>
            <p className="flex  cursor-pointer flex-wrap justify-center ">
              <Link className="mx-2 my-0.5" href="/terms/?layout=no">
                {t('用户协议')}
              </Link>
              <Link className="mx-2 my-0.5" href="/policy">
                {t('导航-服务条款')}
              </Link>
              <Link className="mx-2 my-0.5" href="/cookie-policy/">
                {t('导航-cookie协议')}
              </Link>
              {showFramework && (
                <span className="mx-2 my-0.5" onClick={openKaamel}>
                  {t('隐私设置')}
                </span>
              )}
              {showICP && (
                <Link
                  className="mx-2 my-0.5 w-full text-center sm:w-auto"
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                >
                  蜀ICP备2023029067号-3
                </Link>
              )}
            </p>
          </div>
          <div className="mx-auto mt-6 w-[357px] lg:mt-15 lg:w-[476px] xl:mt-[110px] xl:w-[714px]">
            <Image src="/images/footer/footer-bg.jpg" alt="WuKong" width={724} height={240} quality={100} />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
