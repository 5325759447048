import React from 'react';
import classNames from 'classnames';

const MenuIcon = React.memo<{ open: boolean; className?: string }>(({ open, className }) => (
  <div
    className={classNames(
      ' inline-flex h-[12px] w-[18px] flex-col items-center justify-between align-middle transition-transform  [&_i]:block [&_i]:h-0.5 [&_i]:w-full [&_i]:rounded-sm [&_i]:bg-wk-words-1 [&_i]:transition-all   ',
      open && 'translate-X-[5px]',
      className,
    )}
  >
    <i className={classNames(open && 'translate-y-[5px] rotate-45')} />
    <i className={classNames(open && 'opacity-0 ')} />
    <i className={classNames(open && 'translate-y-[-5px] -rotate-45')} />
  </div>
));
MenuIcon.displayName = 'MenuIcon';
export default MenuIcon;
