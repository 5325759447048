/*
容器：
功能：
  滚动功能
使用：在页面层级使用
*/
import React from 'react';
import Footer, { FooterProps } from '@/components/Footer';
// import { ReactLenis } from '@studio-freight/react-lenis';
import Nav from '../Nav';

interface HomeLayoutProps {
  children: React.ReactElement;
  // 尾部留资的样式
  tooterTheme?: FooterProps['tooterTheme'];
}
const HomeLayout = ({ children, tooterTheme }: HomeLayoutProps) => {
  return (
    <>
      <Nav />
      <div>{children}</div>
      <Footer tooterTheme={tooterTheme} />
    </>
  );
};

export default HomeLayout;
