import React, { useCallback, useState } from 'react';
import Arrow2 from '@/components/Icon/Arrow2';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import cls from 'classnames';
import useGTM from '@/hooks/useGtag';
import { traceEvent } from '@wk/wk-gatherer';
import { useRouter } from 'next/router';
import { SelectorChildrenItem, theme as themeType } from '@/components/Nav/generatePaths';
import PC from '@/components/Device/PC';
import Mobile from '@/components/Device/Mobile';

interface SelectorProps {
  options: SelectorChildrenItem[];
  children: React.ReactNode;
  theme?: themeType;
  align?: 'left' | 'right';
  hiddenArrow?: boolean;
  bodyClassName?: string;
}

const Select: React.FC<SelectorProps> = ({
  options,
  children,
  theme = 'default',
  align = 'left',
  hiddenArrow = false,
  bodyClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const sendDataToGTM = useGTM();
  const gaFix = 'O_Navigation_';

  // 获取当前路径
  const currentPath = router.pathname;

  const { i18n } = useTranslation(['common']);

  const handleContainerClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleGA = (e: React.MouseEvent, item: SelectorChildrenItem) => {
    if (item?.ga) {
      const gaNameFix = `${gaFix}${item?.ga}`;
      // e.stopPropagation();
      sendDataToGTM(gaNameFix);
      traceEvent({
        data: {
          _event: gaNameFix,
          // 点击的所有页面名称
          page_name: currentPath,
        },
      });
    }
  };

  const themeDefault = () => {
    return (
      <div
        className={cls(
          'relative flex flex-col lg:space-y-2 lg:rounded-3xl lg:bg-white lg:p-3  lg:shadow-[0px_12px_20px_0px_rgba(0,0,0,0.06)]',
          bodyClassName,
        )}
      >
        {options.map((item, index) => {
          return (
            <li
              className="block whitespace-nowrap  py-3 text-base text-wk-words-2 first:mt-3 lg:rounded-xl   lg:px-3 lg:py-2 lg:text-sm  lg:first:mt-0  lg:hover:bg-wk-bg-1 lg:hover:text-wk-words-1 xl:text-base [&&_strong]:font-normal"
              key={index}
            >
              {item?.render ? (
                item.render()
              ) : (
                <strong>
                  <Link
                    className=" block lg:inline-block"
                    target={item.target}
                    href={(item?.hrefZh && i18n?.language === 'zh' ? item?.hrefZh : item.href) || ''}
                    onClick={(e) => {
                      handleGA(e, item);
                    }}
                  >
                    {item.label}
                  </Link>
                </strong>
              )}
            </li>
          );
        })}
      </div>
    );
  };

  const themeClass = () => {
    return (
      <>
        <Mobile>{themeDefault()}</Mobile>
        <PC>
          <div className=" relative flex flex-row space-x-3 rounded-3xl bg-white shadow-[0px_12px_20px_0px_rgba(0,0,0,0.06)]  lg:p-3 [&&_strong]:font-normal">
            {options.map((item, index) => {
              return (
                <li
                  className={cls(
                    'box-content block w-[220px] rounded-xl  p-0.5',
                    item.subject === 'chinese' &&
                      'bg-[linear-gradient(277.61deg,rgba(255,176,83,0.06)_-3.09%,rgba(255,83,83,.08)_72.8%,rgba(252,50,86,0.08)_128.81%)] hover:bg-[linear-gradient(277.61deg,rgba(255,176,83,0.81)_-3.09%,rgba(255,83,83,1)_72.8%,rgba(252,50,86,1)_128.81%)]',
                    item.subject === 'math' &&
                      'bg-[linear-gradient(276.37deg,rgba(0,178,255,0.06)_-0.81%,rgba(0,146,255,0.08)_57.87%,rgba(0,56,255,0.08)_133.39%)] hover:bg-[linear-gradient(276.37deg,rgba(0,178,255,0.77)_-0.81%,rgba(0,146,255,1)_57.87%,rgba(0,56,255,1)_133.39%)]',
                    item.subject === 'english' &&
                      'bg-[linear-gradient(274.01deg,rgba(248,66,186,0.04)_0.09%,rgba(134,66,248,0.08)_59.67%,rgba(70,66,248,0.08)_132.58%)] hover:bg-[linear-gradient(274.01deg,rgba(248,66,186,0.51)_0.09%,rgba(134,66,248,1)_59.67%,rgba(70,66,248,1)_132.58%)]',
                    item?.isCurrentPath &&
                      item.subject === 'chinese' &&
                      'bg-[linear-gradient(277.61deg,rgba(255,176,83,0.81)_-3.09%,rgba(255,83,83,1)_72.8%,rgba(252,50,86,1)_128.81%)]',
                    item?.isCurrentPath &&
                      item.subject === 'math' &&
                      'bg-[linear-gradient(276.37deg,rgba(0,178,255,0.77)_-0.81%,rgba(0,146,255,1)_57.87%,rgba(0,56,255,1)_133.39%)]',
                    item?.isCurrentPath &&
                      item.subject === 'english' &&
                      'bg-[linear-gradient(274.01deg,rgba(248,66,186,0.51)_0.09%,rgba(134,66,248,1)_59.67%,rgba(70,66,248,1)_132.58%)]',
                  )}
                  key={index}
                >
                  <div
                    className={cls(
                      ' rounded-[10px] bg-white ',
                      // i18n.language === 'en' && 'w-48',
                      item.subject === 'chinese' &&
                        ' bg-[linear-gradient(278deg,rgba(255,176,83,0.03)_-3.09%,rgba(255,83,83,0.04)_56.31%,rgba(252,50,86,_0.04)_100.16%)]  ',
                      item.subject === 'math' &&
                        ' bg-[linear-gradient(276deg,rgba(0,178,255,0.03)_-0.81%,rgba(0,146,255,0.04)_43.33%,rgba(0,56,255,0.04)_100.14%)] ',
                      item.subject === 'english' &&
                        ' bg-[linear-gradient(274deg,rgba(248,66,186,0.02)_0.09%,rgba(134,66,248,0.04)_45.06%,rgba(70,66,248,0.04)_100.09%)]  ',
                    )}
                  >
                    <Link
                      href={item.href as string}
                      className={cls(
                        ' block h-full   bg-[length:172px_118px]  bg-right-bottom bg-no-repeat p-4 text-wk-words-1',
                        item.subject === 'chinese' && 'bg-[url("/images/nav/nav-bg-chinese.png")]',
                        item.subject === 'math' && 'bg-[url("/images/nav/nav-bg-math.png")]',
                        item.subject === 'english' && 'bg-[url("/images/nav/nav-bg-english.png")]',
                      )}
                      onClick={(e) => {
                        handleGA(e, item);
                      }}
                    >
                      <p className="">
                        <strong className="[&&&]:font-semibold">{item.label}</strong>
                      </p>
                      <p className="mt-2">
                        <span
                          className={cls(
                            '  inline-block rounded-[2px_8px_8px_8px] border border-white  px-2.5 py-0.5 text-xs font-semibold leading-normal text-white shadow-[0px_4px_9px_rgba(0,0,0,0.04)] ',
                            item.subject === 'chinese' &&
                              'bg-[linear-gradient(278deg,rgba(255,176,83,0.61)-3.09%,rgba(255,83,83,0.75)_56.31%,rgba(252,50,86,0.75)_100.16%)]',
                            item.subject === 'math' &&
                              'bg-[linear-gradient(276deg,rgba(0,178,255,0.46)_-0.81%,rgba(0,146,255,0.60)_43.33%,rgba(0,56,255,0.60)_100.14%)]',
                            item.subject === 'english' &&
                              'bg-[linear-gradient(274deg,rgba(248,66,186,0.36)_0.09%,rgba(134,66,248,0.70)_45.06%,rgba(70,66,248,0.70)_100.09%);]',
                          )}
                        >
                          <strong>{item.navThemeAge}</strong>
                        </span>
                      </p>
                      <p className={cls('mt-3 text-xs')}>
                        <strong>{item.navThemeInfo}</strong>
                      </p>
                    </Link>
                  </div>
                </li>
              );
            })}
          </div>
        </PC>
      </>
    );
  };
  const themeDom = {
    default: themeDefault,
    class: themeClass,
  };
  // 根据 theme 选择对应的样式
  const containerClassName = themeDom[theme];
  // 移动端 用默认样式

  return (
    <div
      onClick={handleContainerClick}
      className="group flex w-full cursor-pointer flex-col items-start  justify-between lg:w-auto lg:flex-row lg:items-center"
    >
      {children}
      {!hiddenArrow && (
        <Arrow2
          className={cls(
            'absolute right-0 ml-1 h-5 w-5 transform transition-transform duration-300 lg:relative lg:h-3 lg:w-3 lg:group-hover:-rotate-180 lg:group-hover:text-wk-red-500',
            isOpen && 'group-hover:-rotate-180',
          )}
        />
      )}
      <ul
        className={cls(
          'relative  w-full duration-300  lg:invisible lg:absolute lg:top-[110%] lg:block lg:w-auto lg:min-w-full lg:pt-6 lg:opacity-0  lg:transition-[top] lg:group-hover:visible lg:group-hover:lg:top-full lg:group-hover:opacity-100',
          isOpen ? 'block' : 'hidden',
          align === 'right' ? 'right-0' : 'left-0',
        )}
      >
        {containerClassName()}
        <svg
          className={cls('absolute top-3 hidden lg:block', align === 'right' ? 'right-4' : 'left-8')}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="12"
          viewBox="0 0 24 12"
          fill="none"
        >
          <path
            d="M10.5858 1.41421C11.3668 0.633165 12.6332 0.633165 13.4142 1.41421L24 12H0L10.5858 1.41421Z"
            fill="white"
          />
        </svg>
      </ul>
    </div>
  );
};

export default Select;
